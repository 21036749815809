@import "../../../_global.scss";

.modalContainer {
    padding: 20px;
    width: 50vw;
    // max-width: 90vw;
    height: fit-content;

    /* max-height: 500px; */
    max-height: 80vh;
    border-radius: 18px;

    /* background-color: #0D0D1C; */
    background-color: $secondBgColor;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1000;
    overflow: scroll;
}

hr {
    border-top: 1px solid #48556F !important;
    width: 90%;
}

input::-ms-reveal {
    filter: invert(100%);
}

.checkbox {
    font-size: 0.875rem;

    input {
        margin-top: 5px !important;
        border: 1px solid green !important;
    }

    :checked {
        background-color: green !important;
    }
}

@media only screen and (max-width: 768px) {
    .modalContainer {
        max-width: 90vw;
    }
}