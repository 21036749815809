$mainBgColor: #efefef !important;
$secondBgColor: #ffffff !important;

$blackColor1: #212121 !important;
$blackColor2: #1F1F1F !important;
$blackColor3: #0A0F1F !important;
$blackColor4: #3EBF8F !important;
$blackColor5: #DEF0FC !important;

$primaryColor: #FFD242 !important;

$primaryColorWithAlpha: #FFD2421a !important;

$inputBgColor: #F2F2F2 !important;
$inputTextColor: #565656 !important;
$blackBtnBg: #FFD242 !important;
$redBtnBg: #FF5757 !important;

$borderColor1: #DDDFE3 !important;
$borderColor2: #E7E7E8 !important;
$borderColor3: #DDDFE3 !important;
$borderColor4: #CBD0DC !important;

$grayTextColor: #9E9E9E !important;
$grayTextColor2: #FCFCFC !important;
$grayTextColor3: #CFCFCF !important;
$grayTextColor4: #ABB4BA !important;
$grayTextColor5: #A9ACB4 !important;

$redGradient: linear-gradient(90deg, #ED2630 10%, #F54262 38%, #ED2630 97%);
$blueGradient: linear-gradient(90deg, #00BCFF 21%, #079CD7 51%, #00A1FF 100%);

.black-text {
    color: $blackColor1;
}

.primary-text {
    color: $primaryColor;
}

.white-text {
    color: white !important;
}

.gray-text {
    color: $grayTextColor;
}

.pointer {
    cursor: pointer;
}

.link-text {
    @extend .primary-text;

    @extend .pointer;
}

.primary-btn {
    color: black !important;
    background-color: $primaryColor;
    font-size: 14px !important;
    font-weight: 700 !important;
}

.black-btn {
    color: white !important;
    background-color: $blackColor3;
    font-size: 14px !important;
    font-weight: 700 !important;
}

.btn {
    border-radius: 12px;
    padding: 8px 16px;
    color: white;
    font-size: 14px;
    margin: 8px;
    cursor: pointer;
}

.input-before-icon {
    position: absolute;

    // left: 15px;
    // top: 12px;
    z-index: 9999;
    font-size: larger;
    color: white !important;
    display: flex;
    justify-content: space-between;
    width: fit-content !important;
}

.input-after-icon {
    position: absolute;

    // right: 30px;
    // top: 20px;
    z-index: 9999;
    font-size: larger;
    color: white !important;
    display: flex;
    justify-content: space-between;
    width: fit-content;
    padding: 10px;
    width: fit-content !important;
}

.bg-red {
    background-color: red !important;
}

.red-text {
    color: red !important;
}

.green-text {
    color: green !important;
}

// ant design
.ant-upload-drag {
    border-width: 1px !important;
    border-style: solid !important;
    border-color: $mainBgColor !important;
    border-radius: 18px !important;
}

:where(.css-dev-only-do-not-override-qnu6hi).ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled):hover,
:where(.css-dev-only-do-not-override-qnu6hi).ant-upload-wrapper .ant-upload-drag-hover:not(.ant-upload-disabled) {
    border-color: $primaryColor;
}

// hide divider in date time picker
.MuiDivider-root, .MuiDivider-fullWidth, .MuiDivider-root, .css-ftc7wc-MuiDivider-root {
    display: none !important;
}

.MuiOutlinedInput-notchedOutline .css-igs3ac {
    border: none !important;
    border-width: 0px !important;
}

.css-igs3ac {
    border: none !important;
    border-width: 0px !important;
}

// export variables for using in JS files
:export {
    primaryColor: $primaryColor;
    primaryColorWithAlpha: $primaryColorWithAlpha;
    mainBgColor: $mainBgColor;
    secondBgColor: $secondBgColor;
}
